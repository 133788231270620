import MenuClose from "./assets/menu-close-icon.svg";
import MenuIcon from "./assets/menu-icon.svg";

let foldBtn = document.querySelector(".fold-btn");
let foleIcon = foldBtn.querySelector("img");
let menus = document.querySelector(".menus");
foldBtn.addEventListener("click", () => {
  if (menus.style.display != "flex") {
    menus.style.display = "flex";
    foleIcon.src = MenuClose;
  } else {
    menus.style.display = "none";
    foleIcon.src = MenuIcon;
  }
});
/**
 * baidu
 */
var _hmt = _hmt || [];
(function () {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?4266a27c59158a5d2b8dbf7b472f5ae2";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();
/**
 * baidu
 */
